import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/privacy.module.css'
import { FiCheck } from 'react-icons/fi'
import Logo from '../components/logo'


const tac = () => {
  const { t } = useTranslation();
  const data = {
    "title": t('tacTitle'),
    "desc": t('aboutDesc'),
    "doc": t('DocTac'),
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row pb--125">
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`section-title text-center`}>
                    <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                  </div>
                </div>
              </div>





              {/* <div className="row mb--20">
                <p className="description b1 color-gray mb--0"
                   dangerouslySetInnerHTML={{ __html: t('tacDescription') }}></p> */}
              {/* </div> */}

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle1') }}></h4>
                <p className="description b1 color-gray mb--20"
                  dangerouslySetInnerHTML={{ __html: t('tacArticle1Desc') }}></p>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2') }}></h4>
                <div className="mb--20">
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H3')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H4')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H5')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H6')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle2H7')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H3')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H4')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H5')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H6')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle3H7')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle4') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle4H1')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle5') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle5H1')}}></h5>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle5H1p1')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle5H1p2')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle5H1p3')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle5H1p4')}}></p>
                  </div>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle5H2')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle6') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle6H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle6H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle6H3')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7H3')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7H4')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle7H5')}}></h5>

                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle8') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle8H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle8H2')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle9') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle9H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle9H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle9H3')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10') }}></h4>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1')}}></h5>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P1')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P2')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P3')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P4')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P5')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P6')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P7')}}></p>
                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle10H1P8')}}></p>
                  </div>
                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H3')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H4')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H5')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle10H6')}}></h5>
                  </div>
                </div>

                <div className="row mb--20">
                  <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle11') }}></h4>

                  <div className="mb--20">
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle11H1')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle11H2')}}></h5>
                    <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle11H3')}}></h5>
                  </div>

                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle12') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1')}}></h5>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P1')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P2')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P3')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P4')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P5')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P6')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P7')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P8')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle12H1P9')}}></p>
                    </div>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle12H2')}}></h5>
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle13') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle13H1')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle13H2')}}></h5>

                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle14') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle14H1')}}></h5>

                    </div>
                  </div>
                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15H1')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15H2')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15H3')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15H4')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle15H5')}}></h5>

                    </div>
                  </div>
                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H1')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H2')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H3')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H4')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H5')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H6')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle16H7')}}></h5>

                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle17') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1')}}></h5>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P1')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P2')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P3')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P4')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P5')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P6')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P7')}}></p>
                      <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: t('tacArticle17H1P8')}}></p>
                    </div>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle17H2')}}></h5>
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle18') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle18H1')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle18H2')}}></h5>
                    </div>
                  </div>


                  <div className="row mb--20">
                    <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle19') }}></h4>
                    <div className="mb--20">
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle19H1')}}></h5>
                      <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('tacArticle19H2')}}></h5>
                    </div>
                  </div>
                  <div className="row mb--0">
                    <p className="row w-200 mb--20 b1 color-white" dangerouslySetInnerHTML={{ __html: t('tacApply') }}></p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const query = graphql`
          query($language: String!) {
            locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
            node {
            ns
          data
          language
        }
      }
    }
  }
          `;

export default tac
